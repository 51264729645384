import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';
import { Notification } from '../../providers/notification-provider/notification-provider';

const _notificationColors = {
  info: "bg-blue-100 border-r-4 border-blue-500 text-blue-700",
  error: "bg-red-100 border-r-4 border-red-500 text-red-700",
  success: "bg-green-100 border-r-4 border-green-500 text-green-700",
};

export function NotificationCard(props: { notification: Notification, onRemove: (notificationId: string) => void }) {
  const { notification, onRemove } = props;

  const [isRemoved, setIsRemoved] = useState(false); 

  const handleClose = useCallback(() => {
    setIsRemoved(true);
    setTimeout(() => {
      onRemove(notification.id);
    }, 200);
  }, [onRemove, notification.id])

  return <div className={`flex flex-row justify-between items-center ${isRemoved ? "animate-fadeOut" : "animate-fadeIn"} ${_notificationColors[props.notification.type]} p-4 m-4 mr-0 mt-0 w-80`}>
    <div>
      <p className="font-bold">{props.notification.title}</p>
      <p>{props.notification.message}</p>
    </div>
    {!notification.excludeClose === true && <div>
      <FontAwesomeIcon icon={faClose} className="hover:cursor-pointer" onClick={handleClose} />
    </div>}
  </div>
}
