/**
 * Button to display a hire button
 */
export function HireButton(props: { slim?: boolean; onClick?: () => void }) {
  return (
    <button
      className={`
        pt-${props.slim ? 2 : 4} 
        pb-${props.slim ? 2 : 4} 
        pl-2 pr-2 rounded-xl min-w-36 
        text-center bg-brand-brown 
        text-brand-black border-2 
        border-brand-black
      `}
      onClick={(event) => {
        event.stopPropagation();
        props.onClick?.();
      }}
    >
      {"Hire 👋"}
    </button>
  );
}
