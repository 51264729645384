import { createContext, PropsWithChildren, useContext, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { NotificationCard } from "../../components/notification-card/notification-card";

export type Notification = {
  id: string;
  title?: string;
  message: string;
  type: "success" | "error" | "info";
  excludeClose?: boolean;
}

type NotificationContextType = {
  notifications: Notification[];
  addNotification: (meta: Omit<Notification, "id">) => string;
  editNotification: (id: string, meta: Omit<Notification, "id">) => void;
  removeNotification: (id: string) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export function NotificationProvider(props: PropsWithChildren) {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  function addNotification(meta: Omit<Notification, "id">): string {
    const id = uuidv4();
    setNotifications((prevNotifications) => [...prevNotifications, { ...meta, id }]);

    return id;
  }

  function removeNotification(id: string) {
    setNotifications((prevNotifications) => prevNotifications.filter((n) => n.id !== id));
  }

  function editNotification(id: string, meta: Omit<Notification, "id">) {
    setNotifications((prevNotifications) => prevNotifications.map((n) => n.id === id ? { ...n, ...meta } : n));
  }

  return <NotificationContext.Provider value={{ notifications, addNotification, removeNotification, editNotification }}>
    <NotificationsWrapper notifications={notifications} removeNotification={removeNotification} />
    {props.children}
  </NotificationContext.Provider>
}

function NotificationsWrapper(props: { notifications: Notification[], removeNotification: (id: string) => void}) {
  return <div className="fixed z-50 top-0 right-0 p-8 pr-2 pt-2">
    {props.notifications.slice(0, 3).map((n) => <NotificationCard key={n.id} notification={n} onRemove={props.removeNotification} />)}
  </div>
}

export function useNotification(): NotificationContextType {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error("useNotification must be used within a NotificationProvider");
  }

  return context;
}
