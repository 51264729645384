import { useState } from 'react';
import { faLinkedin, faGithubAlt } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HireButton } from '../../components/hire-button/hire-button';
import { HireModal } from '../../components/hire-modal/hire-modal';
import frontCollabImg from '../../assets/images/front-collab.webp';
import { MenuBar } from '../../components/menu-bar/menu-bar';

export function NewDesignPage() {
  const [isHireModalOpen, setIsHireModalOpen] = useState(false);

  return (
    <div className="flex w-full flex-col font-sans font-medium text-brand-black">
      <HireModal
        isOpen={isHireModalOpen}
        onClose={() => setIsHireModalOpen(false)}
      />
      <div className="sticky top-0 flex w-full flex-row justify-center bg-brand-brown">
        <MenuBar onHireClick={() => setIsHireModalOpen(true)} />
      </div>
        <button onClick={() => setIsHireModalOpen(true)} />
      <div className="flex min-h-[58vh] w-full flex-col items-center bg-brand-brown pb-16 font-sans font-medium text-brand-black">
        <div className="flex flex-col items-center gap-8 pt-36">
          <p className="text-4xl">Accelerate your projects</p>
          <p className="text-xl font-normal">
            Outsource, collaborate, innovate
          </p>
          <div className="mt-16 flex flex-row gap-16">
            <button
              disabled
              className="min-w-36 rounded-xl bg-brand-black pb-4 pl-2 pr-2 pt-4 text-center text-brand-brown disabled:bg-gray-700"
            >
              {'See more'}
            </button>
            <HireButton onClick={() => setIsHireModalOpen(true)} />
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col items-center bg-white pb-16 pt-16">
        <div className="flex container flex-col gap-16 text-2xl font-medium">
          <p>Projects</p>
          <div className="flex flex-row justify-between gap-16">
            <Card
              title="Coming soon"
              contentText="Big projects are coming soon. Stay tuned."
              tag="innovation"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col items-center bg-brand-brown pb-16 pt-16 font-sans font-medium text-brand-black">
        <div className="flex container flex-col gap-16 pb-16 text-2xl font-medium">
          <p>Collaboration</p>
        </div>
        <div className="flex container flex-row flex-wrap items-center justify-between gap-16">
          <img
            src={frontCollabImg}
            className="min-w-72 max-w-[395px] rounded-xl shadow"
            alt=""
          />
          <p className="min-w-72 max-w-[450px]">
            In every project, I prioritize open communication and active
            collaboration with my clients to ensure their vision is fully
            realized.
            <br />
            <br />
            I work closely with you to understand your unique needs and goals,
            providing regular updates and seeking your input at every stage.
            <br />
            <br />
            My approach is flexible and adaptive, ensuring that we can overcome
            challenges together and achieve the best possible results.
            <br />
            <br />
            Let's work hand-in-hand to turn your ideas into reality.
          </p>
        </div>
      </div>
      <div className="flex w-full flex-row justify-center bg-brand-black text-xs text-brand-brown">
        <div className="flex container flex-col items-center gap-4 pb-4 pt-8">
          <div className="flex flex-row flex-wrap justify-center gap-16 text-4xl text-brand-brown">
            <a href="https://www.linkedin.com/in/casper-thomsen-9b3325174/">
              <FontAwesomeIcon icon={faGithubAlt} />
            </a>
            <a href="https://github.com/CTxD">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
          <p>CVR: 37633313</p>
          <p>© 2024 - All rights reserved</p>
        </div>
      </div>
    </div>
  );
}

function Card(props: {
  title: string;
  contentText: string;
  tag?: string;
  link?: string;
}) {
  return (
    <div className="flex min-h-[200px] min-w-[320px] flex-col gap-2 rounded-xl bg-brand-brown p-4">
      {props.tag && <p className="text-xs">{props.tag}</p>}
      <p className="text-xl">{props.title.toUpperCase()}</p>
      <p className="text-lg font-normal">{props.contentText}</p>
      {props.link && (
        <a className="mt-auto flex justify-end text-xs" href={props.link}>
          {'Learn more'}
        </a>
      )}
    </div>
  );
}

// Color ideas
// #c4d7bcc9
// #d0d3c8
