import { useEffect } from "react";
import { HireButton } from "../hire-button/hire-button";

export function MenuBar(props: {
  onHireClick: () => void;
}) {
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const logoDev = document.getElementById('logo-Dev');

      if (scrollPosition > 50) {
        logoDev?.classList.add('animate-slideOut');
      } else {
        logoDev?.classList.remove('animate-slideOut');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="container flex pt-4 pb-4 bg-brand-brown justify-between font-semibold sticky top-0 items-center align-middle">
      <a href="/">
        <span>CT</span>
        <span id="logo-Dev">Dev</span>
      </a>
      <ul className="flex flex-row gap-8 items-center">
        <li><a href="/posts?tag=project">Projects</a></li>
        <li>
          <HireButton slim onClick={props.onHireClick} />
        </li>
      </ul>
    </div>
  );
}
