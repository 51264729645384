export class EmailService {
  /**
  * Sends an email to the owner of the website.
  * @param email The email of the sender.
  * @param phone The phone number of the sender.
  * @param message The message of the sender.
  * @returns A promise that resolves when the email is sent.
  * @throws An error if the email fails to send.
  */
  static async sendEmailToOwner(email: string, phone: string, message: string): Promise<void> {
    const emailToOwnerEndpoint = "/api/mail/send-email-to-owner";

    const formData = new FormData();
    formData.append("content", "Email: " + email + "\nPhone: " + phone + "\nMessage: " + message);
    const sendEmailResponse = await fetch(emailToOwnerEndpoint, {
      method: "POST",
      body: formData, 
    });

    if (sendEmailResponse.status !== 200) {
      throw new Error("Failed to send email to owner");
    }
  }
}
