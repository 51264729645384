import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import { NewDesignPage } from "./pages/new-design/new-design-page";
import { PostsPage } from "./pages/posts/posts-page";
import { NotificationProvider } from "./providers/notification-provider/notification-provider";

const router = createBrowserRouter([
  {
    path: "/",
    element: <NewDesignPage />,
  },
  {
    path: "/posts",
    element: <PostsPage />
  }
]);

function App() {
  return (
    <React.StrictMode>
      <NotificationProvider>
        <RouterProvider router={router} />
      </NotificationProvider>
    </React.StrictMode>
  );
}

export default App;
