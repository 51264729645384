import { faSpinner, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';
import ContactImg from '../../assets/images/contact.webp';
import { useNotification } from '../../providers/notification-provider/notification-provider';
import { EmailService } from '../../services/email/email_service';

// Refactor this to be a Context Provided component when extending pages.
// Details:
// - The modal should be a fixed position div that covers the entire screen
// - The modal should have both mobile and desktop designs
export function HireModal(props: { isOpen: boolean; onClose: (error?: Error) => void }) {
  const { onClose } = props;
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { addNotification, editNotification  } = useNotification();

  const handleClose = useCallback((error?: Error) => {
    setIsFadingOut(true);
    setTimeout(() => {
      setIsFadingOut(false);
      onClose(error);
    }, 200);
  }, [onClose, setIsFadingOut]);

  const handleSubmit = useCallback(
    async (formData: ContactFormData) => {
      const notificationId = addNotification({ title: "Loading", type: "info", message: "Sending email...", excludeClose: true });
      setIsLoading(true);

      try {
        await EmailService.sendEmailToOwner(
          formData.email,
          formData.phone,
          formData.message
        );
        
        handleClose();

        editNotification(notificationId, { title: "Success", type: "success", message: "Email sent successfully", excludeClose: false});
      } catch (error) {
        if (error instanceof Error) {
          handleClose(error);
        } else {
          handleClose(new Error("Failed to send an email"));
        }

        editNotification(notificationId, { title: "Something went wrong", type: "error", message: "Please try again later", excludeClose: false});
      } finally {
        setIsLoading(false);
      }
    },
    [handleClose, setIsLoading, addNotification, editNotification]
  );

  return (
    <div>
      <div
        className={`fixed left-0 top-0 z-10 h-full w-full bg-[rgba(0,0,0,0.5)] ${props.isOpen ? 'fixed' : 'hidden'} ${isFadingOut ? 'animate-fadeOut' : props.isOpen ? 'animate-fadeIn' : ''}`}
        onClick={() => handleClose()}
      />
      <div
        className={`flex flex-col items-center ${props.isOpen ? 'fixed' : 'hidden'} ${isFadingOut ? 'animate-fadeOut' : props.isOpen ? 'animate-fadeIn' : ''} inset-0 z-30 m-auto h-[500px] w-full max-w-[800px] rounded-xl border-2 border-brand-black bg-white text-brand-black shadow`}
      >
        <div className="absolute right-4 top-5">
          <FontAwesomeIcon
            className="hover:cursor-pointer"
            onClick={() => handleClose()}
            icon={faX}
          />
        </div>
        <div className="flex h-full w-full flex-row items-center">
          <div className="flex h-full w-1/2 flex-col items-center justify-center rounded-l-xl border-r-brand-brown bg-[#fff7ea]">
            <img src={ContactImg} className="rounded-l-xl" alt="" />
          </div>
          <div className="flex w-1/2 flex-col gap-4 p-4">
            <div className="flex flex-col gap-4">
              <p className="text-2xl">Let's get in touch</p>
              <ContactForm onSubmit={handleSubmit} loading={isLoading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type ContactFormData = {
  email: string;
  phone: string;
  message: string;
};

function ContactForm(props: {
  onSubmit: (formData: ContactFormData) => void;
  loading: boolean;
}) {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = useCallback(() => {
    props.onSubmit({ email, phone, message });
  }, [email, phone, message, props]);

  return (
    <div className="flex flex-col gap-4">
      <input
        type="email"
        onChange={(event) => setEmail(event.target.value)}
        placeholder="Email"
        className="block w-full rounded-lg border border-brand-black border-l-8 p-2.5 text-sm text-brand-black placeholder-gray-400 invalid:border-l-red-500 valid:border-l-green-500"
        required
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
      />
      <input
        type="tel"
        onChange={(event) => setPhone(event.target.value)}
        placeholder="Phone"
        className="block w-full rounded-lg border border-brand-black border-l-8 p-2.5 text-sm text-brand-black placeholder-gray-400 invalid:border-l-red-500 valid:border-l-yellow-500"
        pattern="^\+?\d{8,18}$"
      />
      <textarea
        placeholder="Tell me about your project..."
        onChange={(event) => setMessage(event.target.value)}
        className="block h-48 w-full resize-none rounded-lg border border-brand-black p-2.5 text-sm text-brand-black placeholder-gray-400 focus:border-brand-black focus:ring-brand-black"
      />
      <div className="flex flex-row justify-end">
        <button
          onClick={handleSubmit}
          disabled={props.loading}
          className="w-1/2 min-w-36 rounded-xl border-2 border-brand-black pb-2 pl-4 pr-4 pt-2 text-center text-brand-black"
        >
          {props.loading ? <FontAwesomeIcon icon={faSpinner} className="animate-spin"/> : "Submit"} 
        </button>
      </div>
    </div>
  );
}
