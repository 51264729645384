import { faGithubAlt, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { HireModal } from "../../components/hire-modal/hire-modal";
import { MenuBar } from "../../components/menu-bar/menu-bar";

export function PostsPage() {
  const [isHireModalOpen, setIsHireModalOpen] = useState(false);

  return (
    <div className="flex w-full flex-col font-sans font-medium text-brand-black">
      <HireModal
        isOpen={isHireModalOpen}
        onClose={() => setIsHireModalOpen(false)}
      />
      <div className="sticky top-0 flex w-full flex-row justify-center bg-brand-brown">
        <MenuBar onHireClick={() => setIsHireModalOpen(true)} />
      </div>
        <button onClick={() => setIsHireModalOpen(true)} />
      <div className="flex min-h-[25vh] w-full flex-col items-center bg-brand-brown pb-16 font-sans font-medium text-brand-black">
        <div className="flex container flex-col items-center gap-4 pt-16">
          <p className="text-2xl">Projects and Posts</p>
          <p>Various projects and blog posts</p>
        </div>
      </div>
      <div className="flex w-full flex-col items-center bg-white pb-16 pt-16">
        <div className="flex container min-h-40 flex-col gap-16 text-2xl font-medium">
          <p>Featured</p>
        </div>
      </div>
      <div className="flex w-full flex-col items-center bg-brand-brown pb-16 pt-16">
        <div className="flex container min-h-40 flex-col gap-16 text-2xl font-medium">
          <p>Projects and Posts</p>
        </div>
      </div>
      <div className="flex w-full flex-row justify-center bg-brand-black text-xs text-brand-brown">
        <div className="flex container flex-col items-center gap-4 pb-4 pt-8">
          <div className="flex flex-row flex-wrap justify-center gap-16 text-4xl text-brand-brown">
            <a href="https://www.linkedin.com/in/casper-thomsen-9b3325174/">
              <FontAwesomeIcon icon={faGithubAlt} />
            </a>
            <a href="https://github.com/CTxD">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
          <p>CVR: 37633313</p>
          <p>© 2024 - All rights reserved</p>
        </div>
      </div>
    </div>
  );
}
